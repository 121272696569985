import './DoublePaneLayout.css';
import SearchIcon from '@/components/Icons/SearchIcon';
import Loader from '@/components/Shared/Loader/Loader';

interface SearchBarProps {
  handler: (e: Event) => void;
  placeholder: string;
}

const SearchBar = (context: any) => {
  const props = context.props as SearchBarProps;

  const { handler, placeholder } = props;

  return (
    <form onSubmit={handler} class="search-form">
      <SearchIcon />
      <input type="text" class="search-input" placeholder={placeholder} />
    </form>
  );
};

interface FilterAndSortProps {
  filterHandler: () => void;
  sortHandler: () => void;
}

const FilterAndSort = (context: any) => {
  const props = context.props as FilterAndSortProps;

  const { filterHandler, sortHandler } = props;

  if (!sortHandler || !filterHandler) return null;

  return (
    <div class="filter-sort-container">
      <select name="sort" onChange={sortHandler} class="sort">
        <option value="">Recent</option>
      </select>

      <select name="sort" onChange={filterHandler} class="filter">
        <option value="">Show All</option>
      </select>
    </div>
  );
};

interface DoublePaneLayoutProps {
  filterHandler: () => void;
  search: {
    handler: () => void;
    placeholder: string;
  };
  renderSidebarItem: (item: any) => any;
  sidebarLoading: boolean;
  sidebarItems: any[];
  sidebarLink: string;
  sortHandler: () => void;
  renderSidebarHeader: () => any;
}

const DoublePaneLayout = (context: any) => {
  const { children } = context;
  const props = context.props as DoublePaneLayoutProps;

  const {
    filterHandler,
    search,
    sidebarLoading,
    sidebarItems,
    sidebarLink,
    sortHandler,
    renderSidebarItem: RenderSidebarItem,
    renderSidebarHeader: RenderSidebarHeader,
  } = props;

  return (
    <div class="pane-wrapper">
      <aside class="pane-aside">

        {
          RenderSidebarHeader && (
            <RenderSidebarHeader />
          )
        }

        {
          search
          && (
            <SearchBar
              handler={search.handler}
              placeholder={search.placeholder}
            />
          )
        }

        <FilterAndSort sortHandler={sortHandler} filterHandler={filterHandler} />

        {
          sidebarLoading
            ? <Loader />
            : (
              <div>
                {
                  Array.isArray(sidebarItems) && sidebarItems.length > 0
                    ? (
                      <ul class="pane-aside__list">
                        {
                          sidebarItems.map(item => (
                            <RenderSidebarItem item={item} />
                          ))
                        }
                      </ul>
                    )
                    : (
                      <div class="pane-aside--empty">
                        <em>No entries found</em>
                      </div>
                    )
                }
              </div>
            )
        }

        {
          sidebarLink && (
            <router-link to={sidebarLink}>
              <button class="pane-aside__action">
                <span>+</span>
              </button>
            </router-link>
          )
        }
      </aside>

      <div class="pane-content">
        {children}
      </div>
    </div>
  );
};

export default DoublePaneLayout;
