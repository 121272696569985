import Vue from 'vue';
import gql from 'graphql-tag';
import { ApolloError } from 'apollo-boost';

import DoublePaneLayout from '@/components/Admin/DoublePaneLayout/DoublePaneLayout';
import Button from '@/components/Admin/Button/Button';
import apollo from '@/services/apollo';
import './BookingSlots.css';

export interface BookingSlotItem {
  id?: string;
  day: string;
  time: string;
}

export interface BookingSlotResponse {
  id?: string;
  day: string;
  slots: BookingSlotItem[];
}

export enum ListOfDaysEnum {
  sunday = 'sunday',
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
}

const BookingSlotSidebarItem = (context: any) => {
  const bookingSlot = context.props.item as BookingSlotResponse;

  const deleteBookingSlot = (event: Event, id: string) => {
    event.preventDefault();

    // TODO: Use a modal
    // eslint-disable-next-line no-alert
    const confirmed = window.confirm('Are you sure you want to delete this slot');

    if (!confirmed || !id) return;

    const mutation = gql`
      mutation deleteBookingSlot($id: String!) {
        deleteBookingSlot(id: $id) {
          ok
        }
      }
    `;

    apollo.mutate({
      mutation,
      variables: { id },
    })
      .then(() => {
        (event.target as HTMLAnchorElement).parentElement?.classList.add('hide');
      })
      .catch((err) => {
        console.log('Error deleting booking slot => ', err);
      });
  };

  return (
    <li class="sidebar-item">
      <div class="booking-slot-item" onClick={(event: Event) => event.preventDefault()}>
        <span class="booking-slot-item__title">
          {bookingSlot.day}
        </span>

        {
          Array.isArray(bookingSlot.slots) && bookingSlot.slots.length > 0 && (
            <ol class="booking-slot-item__list">
              {
                bookingSlot.slots.map(slot => (
                  <li key={slot.id} class="booking-slot-item__item">

                    <span>
                      {slot.time}
                    </span>

                    <a onClick={(event: Event) => deleteBookingSlot(event, slot.id as string)}>
                      <img src={require('@/components/Icons/delete.png')} />
                    </a>

                  </li>
                ))
              }
            </ol>
          )
        }
      </div>
    </li>
  );
};

const initialFormValues = {
  day: ListOfDaysEnum.monday,
  time: '',
};

export interface BookingSlotsData {
  bookingSlots: BookingSlotResponse[];
  formData: {
    day: ListOfDaysEnum;
    time: string;
  };
  formError: string;
  loading: boolean;
  submissionInProgress: boolean;
}

const BookingSlots = Vue.extend({
  data(): BookingSlotsData {
    return {
      bookingSlots: [],
      formData: { ...initialFormValues },
      formError: '',
      loading: true,
      submissionInProgress: false,
    };
  },

  methods: {
    handleAddBookingSlot(event: Event) {
      event.preventDefault();

      this.formError = '';
      this.submissionInProgress = true;

      const { formData } = this;

      const mutation = gql`
        mutation addBookingSlot(
          $day: String!
          $time: String!
        ) {
          addBookingSlot(
            day: $day
            time: $time
          ) {
            id
          }
        }
      `;

      apollo.mutate({
        mutation,
        variables: formData,
      })
        .then(() => {
          this.loadBookingSlots();
          this.formData = { ...initialFormValues };
        })
        .catch((err: ApolloError) => {
          this.formError = err.graphQLErrors.map(({ message }) => message).join(' ');
        })
        .finally(() => {
          this.submissionInProgress = false;
        });
    },

    loadBookingSlots() {
      const query = gql`
        {
          getBookingSlots{
            day
            slots{
              id
              time
            }
          }
        }
      `;

      apollo.query({
        fetchPolicy: 'network-only',
        query,
      })
        .then(({ data }) => {
          this.bookingSlots = data?.getBookingSlots || [];
        })
        .catch((err) => {
          console.log('Error loading clients => ', err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.loadBookingSlots();
  },

  name: 'booking-slots',

  render() {
    return (
      <DoublePaneLayout
        filterHandler={null}
        renderSidebarItem={BookingSlotSidebarItem}
        search={null}
        sidebarLoading={this.loading}
        sidebarItems={this.bookingSlots}
        sidebarLink={null}
        sortHandler={null}
      >
        <div class="new-booking-slot">
          <header class="dashboard-header">
            <h1 class="dashboard-header__title">New Booking Slot</h1>
          </header>

          <form class="dashboard-content" onSubmit={this.handleAddBookingSlot}>

            <div class="form-group">
              <label for="day">Day</label>
              <select name="day" v-model={this.formData.day} id="day" required>
                {
                  Object.keys(ListOfDaysEnum).map(item => (
                    <option value={item}>{item}</option>
                  ))
                }
              </select>
            </div>

            <div class="form-group">
              <label for="time">Time</label>
              <input type="text" name="time" v-model={this.formData.time} id="time" required />
            </div>

            {
              this.formError && (
                <div class="form-group">
                  <div class="form-error">{this.formError}</div>
                </div>
              )
            }

            <div class="form-group">
              <Button loading={this.submissionInProgress} primary type='submit'>Create Slot</Button>
            </div>
          </form>
        </div>
      </DoublePaneLayout>
    );
  },
});

export default BookingSlots;
